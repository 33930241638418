import ProductStyles from "./ProductStyles";

export default function ProductObj(
  id = 0,
  name = `Nome do produto ${++id}`,
  price = "00.00",
  imageURL = "",
  imageURLTmp = "",
  measurement = "unid",
  styles = ProductStyles()
) {
  return {
    id: id,
    name: name,
    price: price,
    imageURL: imageURL,
    measurement: measurement,
    imageURLTmp: imageURLTmp,
    styles: styles,
  };
}

