import { DEFAULT_PRICE_STYLE, NAME_ABOVE_PRODUCT_LAYOUT, ROUNDED_PRICE_STYLE } from "../components/WorkSpace/CanvasViewer/EncarteStyleConstants";

export default function ProductStyles(
  bgColor = "#F59E0B",
  txtColor = "#292524",
  priceBgColor = "#991B1B",
  priceTxtColor = "#F8FAFC",
  fontSize = "32",
  productLayout = NAME_ABOVE_PRODUCT_LAYOUT,
  gridColumn = "",
  gridRow = "",
  priceStyle = DEFAULT_PRICE_STYLE
) {
  return {
    bgColor: bgColor,
    txtColor: txtColor,
    priceBgColor: priceBgColor,
    fontSize: fontSize,
    priceTxtColor: priceTxtColor,
    productLayout: productLayout,
    gridColumn: gridColumn,
    gridRow: gridRow,
    priceStyle: priceStyle
  };
}
