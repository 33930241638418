import { DEFAULT_ENCARTE_MODEL } from "../components/WorkSpace/CanvasViewer/EncarteStyleConstants";
import Brand from "./Marca";
import Styles from "./Styles";
export default function Encarte(
  name = `Encarte do dia ${new Date().getDate()}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`,
  imageURL = "",
  modelUrl = `${DEFAULT_ENCARTE_MODEL}`,
  styles = Styles(),
  products = []
) {
  return {
    name: name,
    imageURL: imageURL,
    styles: styles,
    products: products,
    modelUrl: modelUrl,
  };
}
