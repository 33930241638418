import styled, { css } from "styled-components";
import {
  FONT_SIZES,
  LOGO_SIZE_MEDIUM,
  ROBOTO_FONT,
  ROUND_LOGO_WITH_BORDER,
  ROUND_LOGO_WITHOUT_BORDER,
  SQUARE_LOGO_WITH_BORDER,
} from "../EncarteStyleConstants";
import { BG_COLORS, COLORS } from "../../../sharable/PickerAndSelector";
import { equalsIgnoreCase } from "../../../../services/Utils";

export const CanvasWrapper = styled.div`
width: min-content;
height; min-content;
  @media print {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
`;

export const GridProducts = styled.div`
  display: grid;
  position: relative;
  height: 100%;
  gap: 0.4rem;
  padding: 0.8rem 0 0 0;

  ${({ grid, scale, styles, hasGrid }) => {
    const columns = grid.columns;
    const rows = grid.rows;
    const priceBgColor = styles.priceBgColor;
    const bgColor = styles.bgColor;
    const txtColor = styles.txtColor;
    return css`
      grid-template-columns: repeat(${columns}, 1fr);
      grid-template-rows: repeat(${rows}, 1fr);
      gap: ${10 * scale}px;
      margin: ${20*scale}px;
      ${grid.highLight && !hasGrid
        ? css`
            .product:first-child {
              grid-${grid.highLight}: 1/3;
              
              .product-image {
                background-size: contain !important;
                background-repeat: no-repeat !important;
                height: 100%;
              }
              .product-name {
                margin-top: 0;
                //border-top-right-radius: ${30 * scale}px;
                //border-top-left-radius: ${30 * scale}px;
                //border-bottom-right-radius: 0px;
                //border-bottom-left-radius: 0px;
                border-radius: ${30 * scale}px !important;
              }
              .price {
               
                //height: 100%;
              }
              ${
                !priceBgColor &&
                `

              .price-wrapper {
                background-color: ${bgColor};
                color: ${txtColor};
              }

              .sign {
                color: ${bgColor};
                background-color: ${txtColor}
              }`
              }
              ${
                bgColor &&
                css`
                  background-color: ${txtColor} !important;
                `
              }
            }
          `
        : ""}
    `;
  }}
`;

export const Canvas = styled.div`
  margin-top: 3rem;
  @media print {
    margin-top: 0;
  }
  display: grid;
  grid-template-columns: 1fr;
  background-size: cover;
  position: relative;
  overflow: hidden;
  ${({ scale, model, styles, dimensions }) => {
    const dim = dimensions;
    const header = dim.header;
    const footer = dim.footer;
    const fontFamily = styles.fontFamily;
    const txtColor = styles.txtColor;

    return css`
      display: flex;
      background-image: url("${model.urlTmp}");
      flex-direction: column;

      ${Header} {
        height: ${header * scale}px;
        position: relative;
      }
      ${GridProducts} {
        height: ${(dim.height - header - footer) * scale}px;
      }
      ${Footer} {
        height: ${footer * scale}px;
      }

      width: ${dim.width * scale}px;
      height: ${dim.height * scale}px;
      font-family: ${fontFamily ? fontFamily : ROBOTO_FONT} !important;
      color: ${txtColor} !important;
    `;
  }};
`;

export const Footer = styled.div`
  height: 100%;
  text-transform: uppercase;
  line-height: 1.3;
  background-position: bottom;
  background-size: cover;
  text-transform: capitalize;
  ${({ scale, model }) => {
    return css`
      padding: ${24 * scale}px ${54 * scale}px ${12 * scale}px ${54 * scale}px;
      padding-top: 0;
      background-image: url(${model.urlTmp});
      font-size: ${24 * scale}px;
      .logo,
      h1 {
        font-size: ${42 * scale}px;
        line-height: 0.6;
      }

      p {
        margin-top: ${2.4 * scale}rem;
        font-size: ${24 * scale}px;
      }
    `;
  }}
  .logo-social-wrapper {
    gap: 0.4rem;
    align-items: center;
  }

  column-gap: 2rem;
`;

export const Contacts = styled.div``;

export const Header = styled.div`
  position: relative;
  overflow: hidden;
`;

export const BrandLogo = styled.div`
  ${({ logo, scale, logoSize }) => {
    const size = `${logoSize ? logoSize * scale : LOGO_SIZE_MEDIUM * scale}px`;
    return css`
      position: absolute;
      bottom: 0%;
      right: 5%;
      width: ${size};
      height: ${size};
      cursor: move;
      background-position: center;
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      ${!logo &&
      css`
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "Sua logo aqui!";
          font-size: ${32 * scale}px;
          text-align: center;
          font-weight: bold;
        }
      `}
    `;
  }}

  ${({ encarteStyles, scale, headerStyles }) => {
    const hasBorderRadius =
    encarteStyles.logoStyle === ROUND_LOGO_WITH_BORDER ||
    encarteStyles.logoStyle === ROUND_LOGO_WITHOUT_BORDER;
    const hasBorder =
    encarteStyles.logoStyle === ROUND_LOGO_WITH_BORDER ||
    encarteStyles.logoStyle === SQUARE_LOGO_WITH_BORDER;
    return css`
      ${hasBorderRadius && "border-radius: 50%;"}
      ${hasBorder &&
      `border: ${10 * scale}px solid ${
        headerStyles.bgColor
      }; background-color: #fff;`}
    `;
  }}
`;

export const calcProductStylesOcurrence = (products)  => {
  const colors = [...COLORS];
  const fontSizes = [...FONT_SIZES];
  const txtColorOCurrence = [];
  const priceTxtColorOcurrence = [];
  const bgColorOccurrence = [];
  const priceBgColorOcurrence = [];
  const fontSizeOcurrence = [];
  
  for(let i = 0; i < products.length; i++){
    for(let j = 0; j < colors.length; j++){
      compareValuesAndUpdateOccurenceArray(products[i].styles.txtColor, colors[j], j,txtColorOCurrence)
      compareValuesAndUpdateOccurenceArray(products[i].styles.bgColor, colors[j], j,bgColorOccurrence)
      compareValuesAndUpdateOccurenceArray(products[i].styles.priceTxtColor, colors[j], j,priceTxtColorOcurrence)
      compareValuesAndUpdateOccurenceArray(products[i].styles.priceBgColor, colors[j], j, priceBgColorOcurrence)
    }
    for(let h = 0; h < fontSizes.length; h++) {
      compareValuesAndUpdateOccurenceArray(products[i].styles.fontSize, fontSizes[h], h, fontSizeOcurrence);
   
    }
  }

  const stylesObj = {
    txtColor: colors[getHigherValuePositionFromArray(txtColorOCurrence)],
    bgColor: colors[getHigherValuePositionFromArray(bgColorOccurrence)],
    priceTxtColor: colors[getHigherValuePositionFromArray(priceTxtColorOcurrence)],
    priceBgColor: colors[getHigherValuePositionFromArray(priceBgColorOcurrence)],
    fontSize: fontSizes[getHigherValuePositionFromArray(fontSizeOcurrence)]
  }
  return stylesObj;
}

const getHigherValuePositionFromArray = (array) => {
  let higherValuePosition = undefined;
  let higherValue = 0;
  array.forEach((el, i) => {
    if(el > higherValue) {
      higherValue = el;
      higherValuePosition = i;
    }
  })
  return higherValuePosition;
}

const compareValuesAndUpdateOccurenceArray = (value1, value2, position, ocurrenceArray) => {
  if(equalsIgnoreCase(value1, value2)) {
    if(!ocurrenceArray[position])ocurrenceArray[position] = 1;
    else ocurrenceArray[position] = ocurrenceArray[position] + 1; 
  }

}