import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import Price from "../../sharable/Price";
import Styles from "../../../OBJRep/Styles";
import {
  DEFAULT_PRICE_STYLE,
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
} from "../CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
  h2 {
    text-transform: capitalize;
  }
  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
          overflow: hidden;
      main {
        filter: blur(3px);
      }
    `}
`;

const PricesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Wrapper = styled.div`

  border: 1px solid transparent;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border-color: #d97706;
  }
  transition: all 300ms ease-in-out;
  ${({ selected }) => {
    if (selected)
      return css`
        background-color: #d97706;
      `;
  }}
`;

export const PriceImage = styled.div`
  ${({imageUrl}) => {return css`
    background-image: url('${imageUrl}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    `}}
`
// TODO: Review Styles OBJ Rep. "Why all properties are still there?"
const styles1 = Styles();
const styles2 = Styles();
const styles3 = Styles();
styles1.priceStyle = DEFAULT_PRICE_STYLE;
styles2.priceStyle = ROUNDED_PRICE_STYLE;
styles3.priceStyle = ROUGHLY_ROUNDED_PRICE_STYLE;

export default function PricesMenuView({ props, updateProductStyle, selectedProduct }) {
  const { activeAccount, isAccountPremium } = useOutletContext();
  const [currentPriceStyle, setCurrentPriceStyle] = useState();
  
  useEffect(() => {
    if(selectedProduct && selectedProduct.styles.priceStyle) 
      setCurrentPriceStyle(selectedProduct.styles.priceStyle)
      else setCurrentPriceStyle(DEFAULT_PRICE_STYLE);
  }, [selectedProduct])

  const handlePriceChange = (style) => {
    setCurrentPriceStyle(style.url);
    updateProductStyle("priceStyle", style.url)
    updateProductStyle("priceStyleTmp", style.urlTmp)
  };

  return (
    <>
      <Container isAccountPremium={isAccountPremium}>
        {!isAccountPremium && <PremiumContentProtectiveLayer />}
        <main>
    
        <h2>Preço</h2>
          <PricesWrapper>
            {props.priceStyleList && props.priceStyleList.map(price => {
              return (
              <Wrapper
              className="price-style"
              onClick={() => handlePriceChange(price)}
              selected={
                currentPriceStyle === price.url
                  ? true
                  : false
              }
            >
            <PriceImage imageUrl={price.urlTmp}/>
            </Wrapper>)
           
            })}
          </PricesWrapper>
        </main>
      </Container>
    </>
  );
}
