import React, { useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  ROUGHLY_ROUNDED_PRICE_STYLE,
  ROUND_AND_SQUARE_PRICE_STYLE,
  ROUNDED_PRICE_STYLE,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import { PriceImage } from "../WorkSpace/ToolBarMenuViews/PricesMenuView";

const Container = styled.div`
  height: 100%;
  width:100%;
  justify-content: center;
`;

const Measurement = styled.div`
  text-transform: uppercase;
  position: relative;
  //font-weight: 600;

  text-align: right;
  ${({ color, fontSize, scale }) => {
    const fontScale = fontSize * scale;
    return css`
      font-size: ${fontScale * 2 * 0.3}px !important;
    `;
  }}
`;

const GeneralStyle = css`

  //transform: translate(25%, 25%);
  line-height: 1;
  widht: 100%;
  .number-wrapper {
    font-weight: 800;
    display: flex;
    align-items: center;
  }

  .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .sign {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
  }

  .decimal-unity-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 1;
  }

  .decimal {
    letter-spacing: -1px;
  }

  ${({ styles, scale, position }) => {
    const fontScale = styles.fontSize * scale;
    const signBackgroundColor = styles.priceBgColor;
    const signTextColor = styles.priceTxtColor;
    const pricePosition = position ? position : "absolute";
    return css`
      font-size: ${fontScale * 2.3}px;
      color: ${styles.txtColor} !important;
      position: ${pricePosition};
      .price-wrapper {
        background-color: ${styles.priceBgColor};
        color: ${styles.priceTxtColor};
      }
      .decimal {
        font-size: ${fontScale * 2 * 0.7}px;
      }

      .sign {
        font-size: ${fontScale * 2 * 0.36}px;
        background-color: ${signBackgroundColor};
        color: ${signTextColor};
      }
    `;
  }}
`;


export const PriceContentWrapper = styled.div`
${GeneralStyle}
${({styles, length, imageUrl, scale}) =>{return css`
  //min-width: ${Number(styles.fontSize)*3}px;
  //min-height: ${Number(styles.fontSize)*3}px;
  width: ${((Number(styles.fontSize)* 2.5)/2)*length*scale}px;
  height: ${((Number(styles.fontSize)* 1.1) / 2) * length* scale}px;

  background-image: url('${imageUrl}');

`}}
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

  z-index: 2;
  
  .price-wrapper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  }

  .sign {
    position: relative;
    background-color: transparent;
    }
`
export default function Price({ price, unity, scale, styles, position }) {
 
  return (
    <Container className="price flex justify-center align CONTAINER">
      <PriceContentWrapper       
          className="flex CONTENT WRAPPER"
          scale={scale}
          styles={styles}
          position={position}
          length={price.length}
          imageUrl={styles.priceStyleTmp}
          >
      
      <PriceContent
            price={price}
            styles={styles}
            unity={unity}
            scale={scale}
          />

          </PriceContentWrapper>
    
    </Container>
  );
}

const PriceContent = ({ price, styles, unity, scale }) => {
  return (
    <>
    {price && price.length > 0 && (

      <div className="price-wrapper WRAPPER DO PRICE CONTENT">
        <span className="sign">R$</span>
        <div className="number-wrapper">
          <span className="integer">{price && price.slice(0, -3)}</span>
          <div className="decimal-unity-wrapper">
            <span className="decimal">
              {price && price.slice(-3).replace(".", ",")}
            </span>
            <Measurement
              fontSize={styles.fontSize}
              color={styles.txtColor}
              scale={scale}
              >
              {unity}
            </Measurement>
          </div>
        </div>
      </div>
      )}
    </>
  );
};
