import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  BrandLogo,
  calcProductStylesOcurrence,
  Canvas,
  Footer,
  GridProducts,
  Header,
} from "../Canvas/CanvasGeneralStyles";
import Product from "../../../sharable/EncarteProduct";
import ExpirationDate, {
  ExpirationDateStyle,
  Text,
} from "../../../sharable/ExpirationDate";
import {
  CANVAS_TARGET_CLASSNAME,
  FEED_INSTAGRAM_DIM,
  MAX_PRODUCTS_FEED_FORMAT,
} from "../EncarteStyleConstants";
import BrandAddress, { AddressStyle } from "../../../sharable/BrandAddress";
import {
  onLogoMouseDown,
  onLogoMouseMove,
  onLogoMouseUp,
} from "../SocialMediaCanvasViewer";

const FeedCanvasContainer = styled(Canvas)`
  ${({ scale }) => css`
    background-position-y: ${-220 * scale}px;
  `}
`;

const FeedHeader = styled(Header)``;

const FeedGridProducts = styled(GridProducts)``;

const FeedFooter = styled(Footer)`
  padding: 0;
  width: 100%;
  flex-direction: column;
  display: flex;
  line-height: 1.3;
  justify-content: space-between;
     ${AddressStyle} {
      width: 100%;
      border-radius: 0;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0;
      ${({ styles }) => css`
        color: ${styles.bgColor};
        background-color: ${styles.txtColor};
      `}
      ${({ scale }) => css`
        p {
          font-size: ${20 * scale}px !important;
          display: inline-block;
          margin-top: 0;
        }
        .logo {
          font-size: ${26 * scale}px !important;
        }
      `}
    }
  ${ExpirationDateStyle}, p {
    width: 100%;
    text-align: center;
    margin: 0;
    ${({ scale }) => css`
      font-size: ${20 * scale}px;
    `}
  align-items: center;

`;
export default function FeedCanvas({
  styles,
  products,
  scale,
  brand,
  encarte,
  model,
  grid,
  isTargetCanvas = false,
  updateEncarteProducts
}) {
  const [feedGrid, setFeedGrid] = useState(grid);
  const [productsCache, setProductsCache] = useState([]);
  const [headerAndFooterStyles, setHeaderAndFooterStyles] = useState({});
  useEffect(() => {
    setHeaderAndFooterStyles(calcProductStylesOcurrence(products));
    setProductsCache(products);
  },[products])

  const watchProductsAndGrid = useEffect(() => {
    if (grid) {
      setFeedGrid(grid);
    } else {
      const updateGridPositions = products.length != productsCache.length;
      setFeedGrid(
        calcFeedGrid(
        products, updateEncarteProducts, updateGridPositions
        )
      );
    }
  }, [products, grid]);
  return (
    <FeedCanvasContainer
      scale={scale}
      styles={styles}
      model={model}
      dimensions={FEED_INSTAGRAM_DIM}
      className={isTargetCanvas && CANVAS_TARGET_CLASSNAME}
    >
      <FeedHeader>
        <BrandLogo
          logo={brand.logoURLTmp}
          scale={scale}
          logoSize={styles.logoSize}
          encarteStyles={styles}
          headerStyles={headerAndFooterStyles}
          onMouseDown={onLogoMouseDown}
          onMouseUp={onLogoMouseUp}
        />
      </FeedHeader>
      {feedGrid && (
        <FeedGridProducts
          grid={feedGrid}
          styles={styles}
          hasGrid={grid && true}
          scale={scale}
        >
          {products.slice(0, 8).map((product) => (
            <Product product={product} scale={scale} styles={styles} />
          ))}
        </FeedGridProducts>
      )}

      <FeedFooter model={model} scale={scale} styles={headerAndFooterStyles}>
        <ExpirationDate
          endDate={encarte.endDate}
          startDate={encarte.startDate}
          styles={headerAndFooterStyles}
          scale={scale}
        />
        <BrandAddress brand={brand} scale={scale} styles={styles} />
      </FeedFooter>
    </FeedCanvasContainer>
  );
}

const calcFeedGrid = (products, updateEncarteProducts, updateGridPositions) => {
  let grid = { columns: "", rows: "", highLight: undefined };
  const productsSize = products.length > MAX_PRODUCTS_FEED_FORMAT
  ? MAX_PRODUCTS_FEED_FORMAT
  : products.length
;
  const evenCount = productsSize % 2 === 0 ? productsSize : productsSize + 1;
  const MAX_COLUMNS = 6;
  const MAX_ROWS = 2;

  if (productsSize === 1) {
    grid.columns = 1;
    grid.rows = 1;
  } else if (productsSize === 2) {
    grid.columns = 2;
    grid.rows = 1;
  } else {
    for (let j = 0; j <= MAX_ROWS; j++) {
      for (let i = 0; i <= MAX_COLUMNS; i++) {
        if (i * j === evenCount) {
          grid.columns = i;
          grid.rows = j;
          continue;
        }
      }
    }
  }
  //if(updateGridPositions)
   //updateProductsGridPositions(grid.rows, grid.columns, products.slice(0, 8), updateEncarteProducts);
  grid.highLight =
    grid.columns * grid.rows !== productsSize && grid.columns > 3
      ? "column"
      : grid.columns * grid.rows !== productsSize && grid.columns <= 3
      ? "row"
      : undefined;
  return grid;
};

const updateProductsGridPositions = (rows, columns, products, callback) => {
  let c1 = 1;
  let r1 = 1;
  const pro = [...products];
  for(let p = 0; p < pro.length - 1; p++){
    for(let c = 1; c < columns + 1; c++) {
      for(let r = 1; r < rows + 1; r++) {
          products[p].styles.gridColumn = `${c}/${c+1}`;
          products[p].styles.gridRow= `${r}/${r+1}`;
          continue;
      }
    }
    break;
  }
  console.log(pro);
  callback(pro);
}
