import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import {
  COLORS,
  Picker,
  Selector,
  FONT_FAMILIES,
  TXT_COLORS,
} from "../../sharable/PickerAndSelector";
import {
  FEED_INSTAGRAM,
  FONT_SIZES,
  LOGO_SIZE_LARGE,
  LOGO_SIZE_MEDIUM,
  LOGO_SIZE_SMALL,
  PANFLETO,
  ROUND_LOGO_WITH_BORDER,
  ROUND_LOGO_WITHOUT_BORDER,
  SQUARE_LOGO_WITH_BORDER,
  SQUARE_LOGO_WITHOUT_BORDER,
  STORIES,
} from "../CanvasViewer/EncarteStyleConstants";
import { IonIcon } from "@ionic/react";
import {
  chevronDown,
} from "ionicons/icons";
import { List, ListProduct, ProductItem } from "../../sharable/ListProduct";
import EncarteProduct, { Image } from "../../sharable/EncarteProduct";
import Button from "../../sharable/ActionButton";
import ProductStyles from "../../../OBJRep/ProductStyles";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import { useOutletContext } from "react-router";
import ProductLayout from "./ProductLayout";
import PricesMenuView from "./PricesMenuView";
import { calcProductStylesOcurrence } from "../CanvasViewer/Canvas/CanvasGeneralStyles";
const Container = styled.div`
  padding-bottom: 10rem;

  height: calc(100vh - 8.4rem);
  overflow-y: auto;
  overflow-x: hidden;
`;
const Styles = styled.div`
  gap: 5.4rem;
  h2 {
    font-size: 2.6rem;

    text-transform: capitalize;
  }
`;

const TextStyles = styled.div`
  gap: 3rem;
  display: flex;
  flex-direction: column;
`;

const LogoStyle = styled.div`
  h2 {
    font-size: 2.4rem;
    text-transform: capitalize;
    margin-bottom: 3rem;
  }
  h3 {
    font-size: 1.8rem;
    text-transform: capitalize;
    margin-bottom: 3rem;
  }
`;

const LogoStyleList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 2rem;

`;

const LogoSize = styled.div`
  margin-top: 3.2rem;
  cursor: pointer;

  .size {
    padding: 1.2rem 3.2rem;
    border: 1px solid #d97706;
    transition: all 300ms ease-in-out;
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  .size:hover,
  .size.selected {
    border-color: transparent;
    background-color: #d97706;
  }
`;

const SelectedProductAndProductListWrapper = styled.div`
  height: 0;
  transition: all 400ms ease-in-out;
  transform: translateY(-115%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${({ showProductsList }) =>
    showProductsList &&
    css`
      transform: translateY(0);
      height: min-content;
    `}
  gap: 1.4rem;
  margin-bottom: 3rem;
`;
const SelectProductWrapper = styled.div`
  cursor: pointer;
  h1 {
    text-transform: capitalize;
    margin-bottom: 2rem;
    font-size: 3rem;
    user-select: none;
  }
  overflow-y: hidden;

  ${List} {
    gap: 2rem;

    grid-template-columns: 15rem 15rem;
  }
  ${ProductItem} {
    .price,
    .unity {
      display: none;
    }
  }
  .arrow-icon {
    transition: all 100ms ease-in;
    transform: rotate(360deg);
    ${({ showProductsList }) =>
      showProductsList &&
      css`
        transform: rotate(180deg);
      `}
  }
  span.preview {
    font-size: 1.6rem;
    text-align: center;
    display: inline-block;
    font-weight: bold;
  }
`;

const SelectedProductPreview = styled.div`
  height: 25rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border: 2px solid #d97706;
  border-radius: 15px;
`;
export default function StylesView({ props }) {
  const [selectedProduct, setSelectedProduct] = useState();
  const { isAccountPremium } = useOutletContext();
  const [showProductsList, setShowProductsList] = useState(false);
  const [productStyles, setProductStyles] = useState(ProductStyles());
  const [selectProductList, setSelectProductList] = useState([]);

  useEffect(() => {
    /* update select product list to show only products that appear on encarte */
    switch(props.encarteStyles.format) {
      case FEED_INSTAGRAM:
        setSelectProductList(props.encarteProducts.slice(0, 8));
        break;
      case STORIES:
        setSelectProductList(props.encarteProducts.slice(0,12));
        break;
      case PANFLETO:
        setSelectProductList(props.encarteProducts);
        break;
      }
  }, [props.encarteProducts, props.encarteStyles.format])

  const updatedProductStylesWithMostUsedStyles = useEffect(() => {
   /*update default styles with most used product styles */
    const colors = calcProductStylesOcurrence(props.encarteProducts);
    setProductStyles(colors)


  },[props.encarteProducts])

  useEffect(() => {

    if (selectedProduct && selectedProduct.styles) {
      setProductStyles(selectedProduct.styles);
    } else setProductStyles(calcProductStylesOcurrence(props.encarteProducts));
  }, [selectedProduct]);
  const updateProductStyle = (style, value) => {
    if (selectedProduct) {
      const selectedProductCopy = { ...selectedProduct };
      selectedProductCopy.styles[style] = value;
      let products = [...props.encarteProducts];
      products = products.map((p, i) => {
        if (p.id === selectedProduct.id) {
          p = selectedProductCopy;
        }
        return p;
      });
      setProductStyles(selectedProductCopy.styles);
      setSelectedProduct(selectedProductCopy);

      props.updateEncarteProducts(products);
    } else {
      const s = { ...productStyles };
      s[style] = value;
      setProductStyles(s);
      const products = props.encarteProducts.slice().map(p => {
        p.styles[style] = value
        return p;}
      )
      props.updateEncarteProducts([...products]);
    }
  };

  const updateEncarteStyles = (style, value) => {
    const encarteStylesCopy = {...props.encarteStyles};
    encarteStylesCopy[style] = value;
    props.updateEncarteStyles(encarteStylesCopy);
  }
  const handleLogoUpdate = (e) => {
    if (!e.target.classList.contains("logo")) return;
    const wrapper = e.target.closest(".logos-wrapper");
    wrapper.querySelectorAll(".logo").forEach((n) => {
      n.classList.remove("selected");
    });
    e.target.classList.add("selected");
  };

  const handleLogoSizeUpdate = (e) => {
    if (!e.target.classList.contains("size")) return;
    const size = e.target.getAttribute("data-logo-size");
    updateEncarteStyles("logoSize", size);
    const wrapper = e.target.closest(".size-wrapper");
    wrapper
      .querySelectorAll(".size")
      .forEach((e) => e.classList.remove("selected"));
    e.target.classList.add("selected");
  };

  return (
    <Container>
      <MenuDescription>
        Estilize o encarte da maneira que preferir. Altere cores de fundo e de
        texto, e escolha dentre as fontes disponiveis.
      </MenuDescription>
      <SelectProductWrapper showProductsList={showProductsList}>
        <h1
          className="flex align min-gap"
          onClick={() => {
            setShowProductsList(!showProductsList);
            setSelectedProduct(undefined);
          }}
        >
          Selecionar produto{" "}
          <IonIcon icon={chevronDown} className="arrow-icon" />
        </h1>
        <SelectedProductAndProductListWrapper
          showProductsList={showProductsList}
        >
          {!isAccountPremium && <PremiumContentProtectiveLayer />}
 
          <List className="flex direction-column">
            {selectProductList.map((product) => (
              <ListProduct
                product={product}
                action={() => setSelectedProduct(product)}
              />
            ))}
          </List>
          {selectedProduct != undefined ? (
            <>
              <SelectedProductPreview>
                <EncarteProduct
                  product={selectedProduct}
                  scale={props.scale}
                  styles={props.encarteStyles}
                />
              </SelectedProductPreview>
              <span className="preview">Editando {selectedProduct.name}</span>
            </>
          ) : (
            <span></span>
          )}

        </SelectedProductAndProductListWrapper>
      </SelectProductWrapper>
      <MenuTitle>estilo do encarte</MenuTitle>
      <Styles className="flex direction-column">
        <TextStyles>
          <h2>Texto</h2>
          <Selector
            options={FONT_SIZES}
            txt="Tamanho da fonte"
            callback={(c) => {
              updateProductStyle("fontSize", c);
            }}
            selected={productStyles.fontSize}
          />
          {!selectedProduct && (
            <Selector
              options={FONT_FAMILIES}
              txt="Estilo da fonte"
              callback={(c) => {
                updateEncarteStyles("fontFamily", c);
              }}
              selected={props.encarteStyles.fontFamily}
            />
          )}

          <Picker
            color={productStyles.txtColor}
            txt="cor geral do texto"
            colorsArr={TXT_COLORS}
            callback={(c) => {
              updateProductStyle("txtColor", c);
            }}
            defaultColor="black"
          />
          <Picker
            optional={false}
            color={productStyles.bgColor}
            txt="cor de fundo do produto"
            colorsArr={COLORS}
            callback={(c) => {
              updateProductStyle("bgColor", c);
            }}
            defaultColor="#1E40AF"
          />
          <Picker
            color={productStyles.priceTxtColor}
            txt="cor do preço"
            colorsArr={TXT_COLORS}
            callback={(c) => {
              updateProductStyle("priceTxtColor", c);
            }}
            optional={false}
            defaultColor="#fff"
          />
        </TextStyles>
        <ProductLayout
          props={props}
          selectedProduct={selectedProduct}
          updateProductStyle={updateProductStyle}
        />
        <PricesMenuView props={props} updateProductStyle={updateProductStyle} selectedProduct={selectedProduct}/>
        {!selectedProduct && (
          <LogoStyle>
            <h2>Logo</h2>
            <LogoStyleList
              className="logo-style-wrapper"
              onClick={handleLogoUpdate}
            >
              <LogoStyleComponent
                logo={props.brand.logoURLTmp}
                text="redonda com borda"
                componentLogoStyle={ROUND_LOGO_WITH_BORDER}
                encarteLogoStyle={props.encarteStyles.logoStyle}
                updateEncarteStyles={updateEncarteStyles}
                defaultLogoStyle={true}
              />
              <LogoStyleComponent
                logo={props.brand.logoURLTmp}
                text="redonda sem borda"
                componentLogoStyle={ROUND_LOGO_WITHOUT_BORDER}
                encarteLogoStyle={props.encarteStyles.logoStyle}
                updateEncarteStyles={updateEncarteStyles}
                defaultLogoStyle={false}
              />
              <LogoStyleComponent
                logo={props.brand.logoURLTmp}
                text="Quadrada com borda"
                componentLogoStyle={SQUARE_LOGO_WITH_BORDER}
                encarteLogoStyle={props.encarteStyles.logoStyle}
                updateEncarteStyles={updateEncarteStyles}
              />
              <LogoStyleComponent
                logo={props.brand.logoURLTmp}
                text="quadrada sem borda"
                componentLogoStyle={SQUARE_LOGO_WITHOUT_BORDER}
                encarteLogoStyle={props.encarteStyles.logoStyle}
                updateEncarteStyles={updateEncarteStyles}
              />
            </LogoStyleList>
            <LogoSize className="logo-size">
              <h3>tamanho</h3>
              <div
                className="size-wrapper flex space-around"
                onClick={handleLogoSizeUpdate}
              >
                <div
                  className={`size ${
                    props.encarteStyles.logoSize == LOGO_SIZE_SMALL &&
                    "selected"
                  }`}
                  data-logo-size={LOGO_SIZE_SMALL}
                >
                  Pequena
                </div>
                <div
                  className={`size ${
                    props.encarteStyles.logoSize == LOGO_SIZE_MEDIUM
                      ? "selected"
                      : !props.encarteStyles.logoSize
                      ? "selected"
                      : ""
                  }`}
                  data-logo-size={LOGO_SIZE_MEDIUM}
                >
                  média
                </div>
                <div
                  className={`size ${
                    props.encarteStyles.logoSize == LOGO_SIZE_LARGE &&
                    "selected"
                  }`}
                  data-logo-size={LOGO_SIZE_LARGE}
                >
                  grande
                </div>
              </div>
            </LogoSize>
          </LogoStyle>
        )}
      </Styles>
    </Container>
  );
}

const LogoStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: min-content;
  height: min-content;

  span {
    margin-top: 1rem;
    font-size: 1.4rem;
    text-align: center;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
const LogoImage = styled.div`
  border: 0px solid transparent;
  &:hover {
    border-color: #d97706;
    border-width: 4px;
  }

  transition: borderColor 200ms ease-in-out;
  width: 10rem;
  height: 10rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  ${({ encarteLogoStyle, componentLogoStyle, defaultLogoStyle, logo }) => {
    const isCurrentLogoStyle =
      encarteLogoStyle === componentLogoStyle ||
      (!encarteLogoStyle && defaultLogoStyle)
        ? true
        : false;
    const hasBorderRadius =
      componentLogoStyle === ROUND_LOGO_WITH_BORDER ||
      componentLogoStyle === ROUND_LOGO_WITHOUT_BORDER;
    return css`
      ${isCurrentLogoStyle && "border-color: #d97706; border-width: 4px;"}
      ${hasBorderRadius && "border-radius: 50%; "}
            background-image: url(${logo});
    `;
  }}
`;

const LogoStyleComponent = ({
  logo,
  text,
  encarteLogoStyle,
  componentLogoStyle,
  defaultLogoStyle = false,
  updateEncarteStyles,
}) => {
  return (
    <LogoStyleWrapper className="wrapper flex direction-column">
      <LogoImage
        componentLogoStyle={componentLogoStyle}
        encarteLogoStyle={encarteLogoStyle}
        defaultLogoStyle={defaultLogoStyle}
        logo={logo}
        onClick={() => {
          updateEncarteStyles("logoStyle", componentLogoStyle);
        }}
      />
      <span>{text}</span>
    </LogoStyleWrapper>
  );
};

const removeTextAndBgColorStyles = (product) => {
  if (
    !product.styles.productLayout ||
    !product.styles.gridColumn ||
    !product.styles.gridRow
  ) {
    product.styles = null;
  } else {
    product.txtColor = undefined;
    product.bgColor = undefined;
    product.priceBgColor = undefined;
    product.priceTxtColor = undefined;
    product.styles.productLayout = undefined;
  }
  return product;
};
