import React, { useEffect } from "react";
import styled, { css } from "styled-components";

export const ExpirationDateStyle = styled.p`
  ${({ scale, bgColor, color }) => css`
    font-size: ${24 * scale}px;
    background-color: ${bgColor};
    color: ${color};
  `}
  text-transform: uppercase;
  text-align: center;
  margin-top: 0 !important;
  &::first-letter {
    text-transform: uppercase;
  }
  font-weight: bold;
`;

export default function ExpirationDate({ startDate, endDate, styles, scale }) {
  return (
    <ExpirationDateStyle
      scale={scale}
      bgColor={styles.bgColor}
      color={styles.txtColor}
    >
      {startDate && endDate
        ? extractDayMonth(startDate) == extractDayMonth(endDate)
          ? `Ofertas válidas apenas hoje enquanto durarem os estoques`
          : `Ofertas válidas a partir do dia ${extractDayMonth(
              startDate
            )} até o dia ${extractDayMonth(
              endDate
            )} ou enquanto durarem os estoques`
        : startDate
        ? `Ofertas válidas a partir do dia ${extractDayMonth(
            startDate
          )} enquanto durarem os estoques`
        : endDate
        ? `Ofertas válidas até o dia ${extractDayMonth(
            endDate
          )} ou enquanto durarem os estoques`
        : "Ofertas válidas enquanto durarem os estoques"}
    </ExpirationDateStyle>
  );
}

function extractDayMonth(date) {
  if (!date) return;

  const day =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const month =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  return `${day}/${month}/${date.getUTCFullYear()}`;
}
