import React from "react";
import { IonIcon } from "@ionic/react";
import {
    globeSharp,
    logoFacebook,
    logoInstagram,
    logoWhatsapp,
  } from "ionicons/icons";
import styled, { css } from "styled-components";
import { Contacts } from "../WorkSpace/CanvasViewer/Canvas/CanvasGeneralStyles";
export const ContactsStyle = styled(Contacts)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-transform: lowercase;
  font-weight: bold;
  ${({ scale, styles }) => css`
    margin-top: ${10 * scale}px;
    //gap: ${5 * scale}px;
    span {
      font-size: ${24 * scale}px;
  
    }
      gap: ${14 * scale}px;

    .logo-social-wrapper {
      background-color: ${styles.txtColor};
      color: ${styles.bgColor};
      padding: ${3 * scale}px;
              border-radius: ${50}px;
    }
  `}
`;
export default function BrandContacts({brand, styles, scale}) {

    return (<ContactsStyle styles={styles} scale={scale}>
        {brand.social.website != "" && 
        <div className="logo-social-wrapper flex">
          <IonIcon className="logo" icon={globeSharp} />
          <span className="align">{brand.social.website}</span>
        </div>}
        {brand.social.whatsapp != "" && 
        <div className="logo-social-wrapper flex">
          <IonIcon className="logo" icon={logoWhatsapp} />
          <span className="align">{brand.social.whatsapp}</span>
        </div>}
        {brand.social.facebook != "" && 
        <div className="logo-social-wrapper flex">
          <IonIcon className="logo" icon={logoFacebook} />
          <span className="align">{brand.social.facebook}</span>
        </div>}
        {brand.social.instagram != "" && <div className="logo-social-wrapper flex">
          <IonIcon className="logo" icon={logoInstagram} />
          <span className="align">{brand.social.instagram}</span>
        </div>}
        
      </ContactsStyle>)
}