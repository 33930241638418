import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  cloudUploadOutline,
  logoFacebook,
  options,
  trash,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Input } from "./InputStyle.jsx";
import Button from "./ActionButton.jsx";
import { useOutletContext } from "react-router";
import Loading from "./Loading.jsx";
import { toDataURL } from "../../services/Utils.js";
import { loadLogo, postLogo } from "../../services/BrandLogoService.js";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const Form = styled.div`
  display: flex;
  gap: 7.2rem;

  ${({ flex }) =>
    flex
      ? css`
          flex-direction: ${flex};
          gap: 4.8rem;
          width: 100%;
          padding: 0 7%;
        `
      : ""}
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin-bottom: 2.4rem;
  }
`;

const WrapperTitle = styled.span`
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
  display: inline-block;
  text-transform: capitalize;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;
const LogoInput = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  border-radius: 50px;
`;

const LogoButton = styled.div`
  width: 10rem;
  height: 10rem;

  cursor: pointer;
  border-radius: 2px;
  position: relative;
  ${({ logo }) => {
    return logo
      ? css`
          background-image: url(${logo}) !important;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid #d9d9d9;
          .icon {
            display: none;
          }
        `
      : "background-color: #d9d9d9;";
  }}
  .icon {
    font-size: 2.4rem;
    color: black;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;
const Span = styled.span`
  font-size: 1.4rem;
  text-align: center;
  gap: 0.6rem;

  &.remove-logo {
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    cursor: pointer;
  }

  &.remove-logo:hover {
    color: #dc2626;
  }
`;
//COMPONENT VERIFIED 31/05
export default function MarcaForm({ flex, updateBrand, brand }) {
  const { user, token } = useOutletContext();
  const [wasBrandUpdated, setWasBrandUpdated] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const logoButton = useRef();
  const updateInfo = (type, e) => {
    setWasBrandUpdated(true);
    const data = e.target.getAttribute("data-info");
    const brandCopy = { ...brand };
    if (type !== "") {
      brandCopy[type][data] = e.target.value;
    } else brandCopy[data] = e.target.value;
    updateBrand(brandCopy);
  };

  const submitLogo = async () => {
    const files = document.getElementById("fileInput").files;
    await postLogo(files[0], user.email, token);
    const resource = await loadLogo(user.email, token);
    const brandCopy = { ...brand };
    brandCopy.logoURLTmp = resource.urlTmp;
    brandCopy.logoURL = resource.url;
    updateBrand(brandCopy);
  };

  const saveBrandUpdates = async () => {
    const userCopy = { ...user };
    userCopy.brand = brand;
    const url = `${BASE_URL}user-collection/${user.id}`;
    const res = await fetch(url, {
      method: "PUT",
      headers: { Authorization: token, "Content-Type": "application/json" },
      body: JSON.stringify(userCopy),
    });
    if (res.ok) {alert("Alterações salvas"); setWasBrandUpdated(false);};
  };

  return (
    <Form flex={flex}>
      {brand && (
        <>
          <InputWrapper
            onChange={(e) => {
              updateInfo("", e);
            }}
          >
            <IonIcon className="logo" logo={logoFacebook} size="small" />
            <WrapperTitle>Identidade</WrapperTitle>
            <Input
              data-info="name"
              placeholder="nome da empresa"
              defaultValue={brand.name}
            />
            <Input
              data-info="phrase"
              placeholder="bordão"
              defaultValue={brand.phrase}
            />
            <Wrapper>
              <LogoButton
                ref={logoButton}
                className="button"
                logo={brand.logoURLTmp}
              >
                <IonIcon icon={cloudUploadOutline} className="icon" />
                <>
                  {loadingLogo ? (
                    <Loading />
                  ) : (
                    <LogoInput
                      type="file"
                      id="fileInput"
                      onChange={submitLogo}
                    />
                  )}
                </>
              </LogoButton>

              <Span>
                Clique para selecionar <br></br> sua logomarca
              </Span>
            </Wrapper>
          </InputWrapper>
          <InputWrapper
            onChange={(e) => {
              updateInfo("address", e);
            }}
          >
            <WrapperTitle>localização</WrapperTitle>

            <Input
              data-info="number"
              defaultValue={brand.address.number}
              placeholder="número"
            />
            <Input
              data-info="street"
              defaultValue={brand.address.street}
              placeholder="rua"
            />
            <Input
              data-info="neighborhood"
              defaultValue={brand.address.neighborhood}
              placeholder="bairro"
            />
            <Input
              data-info="city"
              defaultValue={brand.address.city}
              placeholder="cidade"
            />
          </InputWrapper>
          <InputWrapper
            onChange={(e) => {
              updateInfo("social", e);
            }}
          >
            <WrapperTitle>Mídias Sociais</WrapperTitle>
            <Input
              data-info="whatsapp"
              defaultValue={brand.social.whatsapp}
              placeholder={`Whatsapp`}
            />
            <Input
              data-info="instagram"
              defaultValue={brand.social.instagram}
              placeholder="Instagram"
            />
            <Input
              data-info="facebook"
              defaultValue={brand.social.facebook}
              placeholder="Facebook"
            />
            <Input
              data-info="website"
              defaultValue={brand.social.website}
              placeholder="Website"
            />
            {wasBrandUpdated ? <Button
              action={saveBrandUpdates}
              text="salvar alterações"
              $fullWidth
            /> : <Button
            action={()=>{}}
            text="salvar alterações"
            $fullWidth
            $NoBgColor
            color={"fff"}

          /> }
            
          </InputWrapper>
        </>
      )}
    </Form>
  );
}