import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  loadEncarteModel,
  loadEncarteModels,
} from "../../services/ModelsService";
import { devices } from "../../Devices";
import Logo, { LogoIconWrapper, LogoStyle } from "../sharable/Logo";
import Button from "../sharable/ActionButton";
import CanvasViewer, { updatePosition } from "../WorkSpace/CanvasViewer/SocialMediaCanvasViewer";
import { Input } from "../sharable/InputStyle";
import Loading from "../sharable/Loading";
import Toolbar from "../WorkSpace/ToolBarMenuViews/Toolbar";
import html2canvas from "html2canvas";
import {
  CANVAS_TARGET_CLASSNAME,
  CANVAS_VIWER_TARGET_CLASSNAME,
  CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME,
  MAX_PRODUCTS,
  PRICE_STYLE_SEARCH_KEYWORD,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import {
  convertPriceStyleToDataURL,
  convertProductsImagesToDataURL,
  toDataURL,
} from "../../services/Utils";
import { saveAs } from "file-saver";
import Styles from "../../OBJRep/Styles";
import { IonIcon } from "@ionic/react";
import { print } from "ionicons/icons";
import { loadUserProducts } from "./MyProductsView";
import { Grid } from "../WorkSpace/ToolBarMenuViews/Grids";
import { removeProductGridStyles } from "../WorkSpace/ToolBarMenuViews/GridMenuView";
import { retrieveResources } from "../../services/ResourcesService";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const CORS = process.env.REACT_APP_SERVER_BASE_URL;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 4.2rem;
  overflow: hidden;
  @media ${devices.mobileL} {
    padding: 1.2rem 1.2rem;
  }

  .print-icon-span-wrapper {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: color 300ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: #d97706;
    }
    span {
      font-size: 1.2rem;
    }
  }
  .print-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Container = styled.div`
  max-height: calc(100vh) important;
  overflow-y: hidden;

  @media ${devices.tablet} {
    ${LogoIconWrapper} {
      .icon {
        width: 1rem !important;
        height: 1rem;
      }
    }
    ${LogoStyle} {
      font-size: 1.2rem !important;

      .beta {
        font-size: 0.8rem;
        bottom: -1rem;
      }
    }
  }
`;

const Layout = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 35% 65%;
  grid-template-rows: min-content min-content 1fr;
  max-height: 100%;
  @media ${devices.tablet} {
    grid-template-columns: 100vw;
    grid-template-rows: min-content min-content;
  }
`;

const EncarteNameBox = styled(Input)`
  width: 20rem;
  background-color: transparent;
  border: none;
  @media ${devices.tablet} {
    width: 10rem;
  }
  border: 1px solid rgba(1, 1, 1, 0.4);
`;

//COMPONENT VERIFIED 31/05
export default function Wo() {
  const { token, user } = useOutletContext();
  const [encarte, setEncarte] = useState();
  const [canvasScale, setCanvasScale] = useState(0.5);
  const [params, setParams] = useSearchParams();
  const [encarteModel, setCurrentEncarteModel] = useState();
  const [brand, setBrand] = useState();
  const [maxProducts, setMaxProducts] = useState(MAX_PRODUCTS);
  const [encarteProducts, setEncarteProducts] = useState([]);
  const [encarteStyles, setEncarteStyles] = useState();
  const [encarteName, setEncarteName] = useState("");
  const [existUpdates, setExistUpdates] = useState(false);
  const [isEncarteDownloading, setIsEncarteDownloading] = useState(false);
  const [availableModels, setAvailableModels] = useState(null);
  const [priceStyleList, setPriceStyleList] = useState(null);
  const [userProducts, setUserProducts] = useState();
  const [productsGrid, setProductsGrid] = useState(undefined);
  const navigate = useNavigate();

  const updateEncarteStyles = (obj) => {
    if (obj.format != encarteStyles.format) {
      setEncarteProducts(removeProductGridStyles(encarteProducts));
    }
    setEncarteStyles(obj);
    setExistUpdates(true);
  };
  const updateGrid = (grid) =>{
    if(!grid) setEncarteProducts(removeProductGridStyles(encarteProducts));
    setProductsGrid(grid)
    setExistUpdates(true)
  }
  const updateEncarteProducts = (arr) => {
    setEncarteProducts(arr);
    setExistUpdates(true);
  };
  const updateBrandInfo = (obj) => {
    setExistUpdates(true);
    setBrand(obj);
  };

  const updateEncarte = (obj) => {
    setExistUpdates(true);
    setEncarte(obj);
  };
  const updateCurrentEncarteModel = (obj) => {
    setExistUpdates(true);
    setCurrentEncarteModel(obj);
  };

  const updateCurrentEncarteName = (obj) => {
    setExistUpdates(true);
    setEncarteName(obj);
  };

  const loadAllAvailableModels = useEffect(() => {
    loadEncarteModels(token).then((result) => setAvailableModels(result));
  }, []);

  const loadAllAvailablePrices = useEffect(() => {
    retrieveResources(token, PRICE_STYLE_SEARCH_KEYWORD, "OTHER").then(result => {setPriceStyleList(result);});
  }, [])

  const fetchUserProducts = useEffect(() => {
    loadUserProducts(token).then((result) => setUserProducts(result));
  }, []);
  const loadEncarte = useEffect(() => {
    const perform = async () => {
      try {
        if (!user) return;
        const encarteId = Number(params.get("encarteId"));
        const res = await fetch(`${BASE_URL}encarte-collection/${encarteId}`, {
          headers: { Authorization: token },
        });
        const element = await res.json();
        console.log(element);
        const encarteModel = await loadEncarteModel(element.modelUrl, token);
        /*CONVERTS BRAND LOGO TO DATA URL TO BE POSSIBLE TO DOWNLOAD */

        /*CONVERTES PRODUCTS IMAGES TO DATA URL TO BE POSSIBLE TO DOWNLOAD */
        let products = (element.products =
          await convertProductsImagesToDataURL(element.products, token));
        products = await convertPriceStyleToDataURL(element.products, token);
        if (element.startDate) element.startDate = new Date(element.startDate);
        if (element.endDate) element.endDate = new Date(element.endDate);
        if(element.styles.rows && element.styles.columns)
          setProductsGrid(Grid(element.styles.columns, element.styles.rows))
        setBrand(user.brand);
        setEncarte(element);
        setCurrentEncarteModel(encarteModel);
        setEncarteProducts(products);
        setEncarteStyles(element.styles);
        setEncarteName(element.name);
      } catch (err) {
        navigate("/encartes");
      }
    };
    perform();
  }, [user]);

  const watchCanvasScale = useEffect(() => {
    if (canvasScale === 3) {
      downloadCanvas().then((res) => {
        setIsEncarteDownloading(false);
      });
      setCanvasScale(0.5);
    }
  }, [canvasScale]);

  const watchesCanvasOverflow = useEffect(() => {
    const canvas = document.querySelector(`.${CANVAS_TARGET_CLASSNAME}`);
    const canvasWrapper = document.querySelector(
      `.${CANVAS_VIWER_TARGET_CLASSNAME}`
    );
    if (!canvas || canvasScale === 3) return;
    const client = canvas.getBoundingClientRect();
    const isOverflowing =
      canvas.offsetWidth > canvasWrapper.offsetWidth ||
      canvas.offsetHeight > canvasWrapper.offsetHeight;
    if (isOverflowing) {
      const s = canvasScale - 0.05;
      setCanvasScale(s);
    }
  }, [canvasScale, encarteStyles]);

  const watchEncarteChanges = useEffect(() => {
    const timer = setTimeout(() => {
      saveEncarte();
    }, 15000);
    return () => {
      clearTimeout(timer);
    };
  }, [encarteProducts, encarteName, encarteStyles, encarteModel]);
  const saveEncarte = async () => {
    if (!existUpdates) return;
    const arr = extractProdInfo(encarteProducts);
    const en = { ...encarte };
    en.products = arr;
    en.modelUrl = encarteModel.url;
    en.styles = encarteStyles;
    if(productsGrid) {
      en.styles.columns = productsGrid.columns;
      en.styles.rows = productsGrid.rows;
    } else   { en.styles.columns = "";
    en.styles.rows = "";}
  
    en.name = encarteName;
    console.log(en);
    const encarteId = params.get("encarteId");
    const url = `${BASE_URL}encarte-collection/${encarteId}`;
    const res = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(en),
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    if (res.ok) setExistUpdates(false);
    else alert("Não foi possível salvar o encarte! \n" + res.statusText);
  };

  const handleEncarteDownload = async () => {
    setIsEncarteDownloading((is) => true);
    await saveEncarte();
    setCanvasScale(3);
  };

  const downloadCanvas = async () => {
    const canvas = document.querySelector(`.${CANVAS_TARGET_CLASSNAME}`);

    if (!canvas) return;
    const pngCanvas = await html2canvas(canvas, {
      //scale down to keep image dimension, file size and quality
      scale: 0.33333,
      logging: true,
      allowTaint: true,
      useCORS: true,
    });

    const data = pngCanvas.toDataURL("image/jpeg", 1);
    const userAgent = navigator.userAgent;
    if (/iPhone/gi.test(userAgent) || /iPad/gi.test(userAgent)) {
      pngCanvas.toBlob((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "screenshot.jpeg";
        link.click();
      }, "image/jpeg");
      setTimeout(() => {
        alert("A imagem será aberta em outra aba! Realize o download por lá");
        window.open(data);
      }, 200);
    } else {
      saveAs(data, encarteName, {
        autoBom: true,
      });
    }
  };
  return (
    <Container>
      <Header>
        <Logo showGoBackButton={true} path={"/encartes"} />

        <div className="flex min-gap">
          <div
            className="print-icon-span-wrapper"
            onClick={async () => {
              setIsEncarteDownloading(true);
              await saveEncarte();
              setIsEncarteDownloading(false);
              printCanvas();
            }}
          >
            <IonIcon icon={print} className="print-icon" />
            <span>Imprimir</span>
          </div>
          <EncarteNameBox
            value={encarteName ? encarteName : ""}
            onInput={(e) => {
              updateCurrentEncarteName(e.target.value);
            }}
            placeholder="O nome do seu encarte"
          />
          <Button
            //size="1.4"
            text="baixar"
            action={() => {
              handleEncarteDownload();
            }}
          />
        </div>
      </Header>
      {user != undefined && encarte !== undefined ? (
        <Layout>
          <Toolbar
            maxProducts={maxProducts}
            updateEncarte={updateEncarte}
            encarte={encarte}
            updateCurrentModel={updateCurrentEncarteModel}
            brand={brand}
            updateBrand={updateBrandInfo}
            updateEncarteProducts={updateEncarteProducts}
            encarteProducts={encarteProducts}
            updateEncarteStyles={updateEncarteStyles}
            encarteStyles={encarteStyles}
            availableModels={availableModels}
            model={encarteModel}
            scale={canvasScale}
            updateGrid={updateGrid}
            grid={productsGrid}
            userProducts={userProducts}
            updateUserProducts={setUserProducts}
            priceStyleList={priceStyleList}
          ></Toolbar>
          <CanvasViewer
            scale={canvasScale}
            encarte={encarte}
            maxProducts={maxProducts}
            currentModel={encarteModel}
            brand={brand}
            encarteProducts={encarteProducts}
            styles={encarteStyles}
            updateEncarteStyles={updateEncarteStyles}
            grid={productsGrid}
            updateEncarteProducts={updateEncarteProducts}
            updateGrid={updateGrid}
            priceStyleList={priceStyleList}
          ></CanvasViewer>
        </Layout>
      ) : (
        <Loading txt={"carregando encarte..."} />
      )}
      {isEncarteDownloading ? <Loading txt={"Baixando seu encarte..."} /> : ""}
    </Container>
  );
}

const extractProdInfo = (products) => {
  return products.map((p) => {
    return {
      name: p.name,
      price: p.price,
      imageURL: p.imageURL,
      measurement: p.measurement,
      styles: p.styles
    };
  });
};

function printCanvas() {
  // Store the original body's innerHTML
  const originalContent = document.body.innerHTML;

  // Get the element to print
  const printContent = document.querySelector(
    `.${CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME}`
  ).innerHTML;

  // Replace the body's content with the element's content
  document.body.innerHTML = printContent;

  // Call the print function
  window.print();
  
  // Restore the original content
  setTimeout(() => {
    document.body.innerHTML = originalContent;
    window.location.reload();
}, 2000); // Adjust the delay as needed


}
