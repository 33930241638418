import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  ALEO_FONT,
  ARIAL_FONT,
  BLINKER_FONT,
  FEED_INSTAGRAM,
  OSWALD_FONT,
  PAISAGEM,
  POPPINS_FONT,
  PROTEST_STRIKE_FONT,
  ROBOTO_FONT,
  STAATLICHES_FONT,
  STORIES,
  TEKO_FONT,
  TIMES_NEW_ROMAN_FONT,
  UBUNTU_FONT,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import { ChromePicker, GithubPicker, TwitterPicker } from "react-color";
import Switch from "./Switch";
import { equalsIgnoreCase } from "../../services/Utils";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const Style = styled.div`
  font-size: 1.4rem;

  span {
    display: block;
    margin-bottom: 0.4rem;
  }
  span::first-letter {
    text-transform: lowercase;
  }

  .icon {
    font-size: 1.4rem;
    color: black;
  }

  &.formato-style {
    justify-self: end;
  }
`;

export const Select = styled.select`
  all: unset;
  user-select: none;
  display: block;
  font-size: 1.2rem;
  background-color: #d9d9d9;
  width: 100%;
  //width: min-content;
  padding: 0.8rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    border-color: #d07706;
    color: #d07706;
  }
  option {
    text-align: center;
    text-transform: capitalize;
    color: black !important;
  }
`;

export const ColorPicker = styled.div`
  position: relative;
  .picker {
    opacity: 0;
    transition: all 300ms ease-in-out;
    top: calc(100%) !important;
    position: absolute !important;
    cursor: pointer;
    visibility: hidden;
    z-index: 10;
  }
  .picker.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const Color = styled.div`
  width: 100%;
  height: 4rem;
  border: 1px solid rgba(255, 255, 255, 0.35);
  cursor: pointer;
  border-radius: 3px;
  ${({ color, active }) => {
    return color && active
      ? css`
          background-color: ${color};
        `
      : css`
          background-color: ${color};
          opacity: 0.2;
          cursor: default;
        `;
  }}
`;

export const BgColor = styled(Color)`
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const TxtColor = styled(Color)`
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const Pickers = styled(Styles)`
  grid-column: 1/2;
  grid-row: 1/-1;
  align-items: center;
`;

export const FontFormatStyle = styled(Styles)`
  grid-column: 2/3;
  grid-row: 1/2;
  flex-direction: row;
`;

export const TXT_COLORS = [
 "#E6E6E6",
"#f8fafc",
"#0C0C0C",
"#292524",
"#696969",
"#404040",
];
export const COLORS = [
  //"#ea580c",
  "#f59e0b",
  "#991b1b",
  "#255555",
  "#854d0e",
  "#9a3412",
  "#115e59",
  "#155e75",
  "#075985",
  "#1e40af",
  "#3730a3",
  "#5b21b6",
  "#9d174d",
  "#9f1239",
  "#86198f",
  "#f8fafc",
  "#0C0C0C",
  "#E6E6E6",
  "#404040",
  "#292524",
  "#696969",
];

export const Picker = ({
  txt,
  colorsArr,
  callback,
  color,
  optional,
  defaultColor = "",
}) => {
  const [colors, setColors] = useState([]);
  const [active, setActive] = useState();

  useEffect(() => {
    if (color !== "") setActive(true);
  }, [color]);
  useEffect(() => {
    setColors(colorsArr);
  }, [color]);

  const updateCurrentColorOnPicker = (hex) => {
    const copy = [...colors];
    copy.forEach((color, i) => {
      if (color === hex) {
        copy[i] = colors[0];
        copy[0] = hex;
      }
    });

    setColors(copy);
  };
//TODO:Remove colot switch code
  const handleActiveStyle = (isOn) => {
    setActive((a) => isOn);
    if (isOn) callback(defaultColor);
    else callback("");
  };
  return (
    <Style>
      <div className="flex min-gap">
        <span>{txt}</span>

        {optional && <Switch on={active} callback={handleActiveStyle} />}
      </div>
      <ColorPicker
        onMouseOver={(e) => showPicker(e)}
        onMouseOut={(e) => hiddenPicker(e)}
        className="picker-container"
      >
        <Color
          color={color !== "" ? color : defaultColor}
          active={active}
          className="color-picker"
        />
        {active && (
          <TwitterPicker
            color={color}
            colors={colorsArr}
            onChange={(e) => {
              callback(e.hex);
              updateCurrentColorOnPicker(e.hex);  
            }}
            className="picker"
          />
        )}
      </ColorPicker>
    </Style>
  );
};

export const FONT_FAMILIES = [
  PROTEST_STRIKE_FONT,
  ALEO_FONT,
  BLINKER_FONT,
  OSWALD_FONT,
  POPPINS_FONT,
  ROBOTO_FONT,
  STAATLICHES_FONT,
  TEKO_FONT,
  UBUNTU_FONT,
];

export const ENCARTE_FORMATS = [FEED_INSTAGRAM, STORIES];

export const Selector = ({ txt, callback, options, selected }) => {
  return (
    <Style>
      <span>{txt}</span>
      <Select
        onClick={(e) => {
          callback(e.target.value);
        }}
      >
        {options.map((op) => (
          <option
            className="op"
            selected={equalsIgnoreCase(selected, op) ? "selected" : ""}
            value={op}
          >
            {op}
          </option>
        ))}
      </Select>
    </Style>
  );
};

const showPicker = (e) => {
  if (!e.target.closest(".picker-container")) return;
  const pi = e.target.closest(".picker-container").querySelector(".picker");
  if (!pi) return;
  pi.classList.add("show");
};

const hiddenPicker = (e) => {
  const pi = e.target.closest(".picker-container").querySelector(".picker");
  if (!pi) return;
  pi.classList.remove("show");
};
